<template>
  <div class="recommend">
    <div class="title">{{ title }}：</div>

    <ul>
      <li v-for="item in list" :key="item.id">
        <h3>
          <span @click="viewRecommend(item.link)">{{ item.title }}</span>
        </h3>
      </li>
    </ul>
    <transition-group
      tag="ul"
      v-if="list && !list.length"
      class="skeleton"
      name="list"
    >
      <li v-for="item in 5" :key="item">
        <h3>
          <span class="skeleton-bg"></span>
        </h3>
      </li>
    </transition-group>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, nextTick } from "vue";
import { useRouter } from "vue-router";
export interface RecommnedList {
  link: object;
  id: number;
  title: string;
}

export default defineComponent({
  name: "Recommend",
  props: {
    list: {
      type: Array as PropType<RecommnedList[]>,
      default: [],
    },
    title: String,
  },
  setup() {
    const router = useRouter();

    function viewRecommend(link: object) {
      router.push(link);
    }
    return {
      viewRecommend,
    };
  },
});
</script>

<style lang="scss" scoped>
.recommend {
  width: 355px;
  text-align: left;
  margin: 0 auto;

  box-sizing: border-box;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
  position: relative;
  .title {
    text-align: justify;
    font-size: 16px;
    color: #666666;
    line-height: 25px;
    margin: 20px 10px;
    font-weight: bold;
  }
  li {
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 10px;
      top: 10px;
      width: 5px;
      height: 5px;
      background: #7f7f7f;
      border-radius: 50%;
    }
  }
  span {
    text-align: justify;
    font-size: 14px;
    line-height: 25px;
    display: block;
    color: #666666;
    margin: 10px;
    overflow: hidden;
    padding-left: 10px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  h3 {
    margin: 0;
    font-weight: normal;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}

.skeleton {
  li {
    span {
      margin: 15px 10px;
      height:15px;
    }
    &::before {
      content: "";
      display: none;
      position: absolute;
      left: 10px;
      top: 10px;
      width: 5px;
      height: 5px;
      background: #7f7f7f;
      border-radius: 50%;
    }
  }
  .skeleton-bg {
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 74.5%, 0.2) 25%,
      hsla(0, 0%, 50.6%, 0.24) 37%,
      hsla(0, 0%, 74.5%, 0.2) 63%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
  }
}
.list-enter-active,
.list-leave-active {
  transition: all 1s ease-in-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>
